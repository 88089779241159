import React, { useState  } from 'react';
import axios from 'axios';
import './DemographicsBox.css';

const fakePeople = [
    { FirstName: 'John', LastName: 'Doe', Gender: 'Male', CellPhoneNumber: '215-456-7890', Dob: '1990-01-01', Street: '123 Fake St', State: 'PA', City: 'Faketown', ZipCode: '00000', Ssn: '323-45-6789' },
    { FirstName: 'Jane', LastName: 'Doe', Gender: 'Female', CellPhoneNumber: '215-567-8901', Dob: '1991-02-01', Street: '234 Fake Ave', State: 'PA', City: 'Fakeville', ZipCode: '11111', Ssn: '234-56-7890' },
    { FirstName: 'Jim', LastName: 'Beam', Gender: 'Male', CellPhoneNumber: '215-678-9012', Dob: '1992-03-02', Street: '345 Imaginary Rd', State: 'PA', City: 'Makebelieve', ZipCode: '22222', Ssn: '345-67-8901' },
    { FirstName: 'Jenny', LastName: 'Smith', Gender: 'Female', CellPhoneNumber: '215-789-0123', Dob: '1993-04-03', Street: '456 Fantasy Ln', State: 'PA', City: 'Nowhere', ZipCode: '33333', Ssn: '456-78-9012' },
    { FirstName: 'Jake', LastName: 'Peralta', Gender: 'Male', CellPhoneNumber: '718-890-1234', Dob: '1994-05-04', Street: '567 Pretend Pl', State: 'PA', City: 'Brooklyn', ZipCode: '44444', Ssn: '567-89-0123' },
    { FirstName: 'Amy', LastName: 'Santiago', Gender: 'Female', CellPhoneNumber: '718-901-2345', Dob: '1995-06-05', Street: '678 Fictitious Blvd', State: 'NY', City: 'Brooklyn', ZipCode: '55555', Ssn: '678-90-1234' },
    { FirstName: 'Terry', LastName: 'Jeffords', Gender: 'Male', CellPhoneNumber: '718-012-3456', Dob: '1996-07-06', Street: '789 Mythical Way', State: 'NY', City: 'Brooklyn', ZipCode: '66666', Ssn: '789-01-2345' },
    { FirstName: 'Rosa', LastName: 'Diaz', Gender: 'Female', CellPhoneNumber: '718-123-4567', Dob: '1997-08-07', Street: '890 Untrue Path', State: 'NY', City: 'Brooklyn', ZipCode: '77777', Ssn: '890-12-3456' },
    { FirstName: 'Charles', LastName: 'Boyle', Gender: 'Male', CellPhoneNumber: '718-234-5678', Dob: '1998-09-08', Street: '901 Nonexistent St', State: 'NY', City: 'Brooklyn', ZipCode: '88888', Ssn: '901-23-4567' },
    { FirstName: 'Gina', LastName: 'Linetti', Gender: 'Female', CellPhoneNumber: '718-345-6789', Dob: '1999-10-09', Street: '012 Fabricated Ave', State: 'NY', City: 'Brooklyn', ZipCode: '99999', Ssn: '012-34-5678' },
    { FirstName: 'Raymond', LastName: 'Holt', Gender: 'Male', CellPhoneNumber: '718-456-7890', Dob: '1990-11-10', Street: '123 Invented St', State: 'NY', City: 'Brooklyn', ZipCode: '10101', Ssn: '223-45-6789' },
    { FirstName: 'Henry', LastName: 'Scully', Gender: 'Male', CellPhoneNumber: '718-567-8901', Dob: '1991-12-11', Street: '234 Madeup Ln', State: 'NY', City: 'Brooklyn', ZipCode: '20202', Ssn: '234-56-7890' },
    { FirstName: 'Adrian', LastName: 'Pimento', Gender: 'Male', CellPhoneNumber: '718-678-9012', Dob: '1992-01-12', Street: '345 Phantom St', State: 'NY', City: 'Brooklyn', ZipCode: '30303', Ssn: '345-67-8901' },
    { FirstName: 'Doug', LastName: 'Judy', Gender: 'Male', CellPhoneNumber: '718-789-0123', Dob: '1993-02-13', Street: '456 Fictional Rd', State: 'NY', City: 'Brooklyn', ZipCode: '40404', Ssn: '456-78-9012' },
    { FirstName: 'Vivian', LastName: 'Ludley', Gender: 'Female', CellPhoneNumber: '718-890-1234', Dob: '1994-03-14', Street: '567 Imagined Blvd', State: 'NY', City: 'Brooklyn', ZipCode: '50505', Ssn: '567-89-0123' },
];

const nicknameLookup = {
    John: 'Johnny',
    Jane: 'Janey',
    Jim: 'Jimmy',
    Jenny: 'Jen',
    Jake: 'Jakey',
    Amy: 'Ames',
    Terry: 'Terrence',
    Rosa: 'Rosie',
    Charles: 'Chuck',
    Gina: 'Regina',
    Raymond: 'Ray',
    Henry: 'Hank',
    Adrian: 'Adriano',
    Doug: 'Douglas',
    Vivian: 'Viv',
  };
  

function getRandomPeople(peopleArray, count) {
    let selectedIndices = new Set();
    while (selectedIndices.size < count) {
        let randomIndex = Math.floor(Math.random() * peopleArray.length);
        selectedIndices.add(randomIndex);
    }

    // Map selected indices to people, adding an Identifier starting at 1
    return Array.from(selectedIndices).map((index, idx) => ({
        ...peopleArray[index],
        Identifier: (idx + 1).toString() // Start Identifier at 1 and increment
    }));
}

const formatPeopleAsApiResponse = (people) => {
    // Assuming the API response structure you want to mimic, adjust as necessary
    return people.map((person, index) => ({
      Id: `group${index + 1}`, // Example groupId, adjust based on actual API logic
      Records: [{
        ...person,
        Identifier: person.Identifier, // Assuming an identifier similar to your mapping logic
        Source: 'initial' // Mark these as initially loaded
      }]
    }));
  };



function CSVCreator() {
    const initialPeople = getRandomPeople(fakePeople, 3);
  const formattedPeople = formatPeopleAsApiResponse(initialPeople);

  const [selectedPeople, setSelectedPeople] = useState(initialPeople);
  const [groupedPeople, setGroupedPeople] = useState(formattedPeople);

  const [highestIdentifier, setHighestIdentifier] = useState(0);
  const [showHash, setShowHash] = useState(false);

    

  const addRandomPerson = async () => {
    // Filter out people who are already in selectedPeople based on FirstName
    const availablePeople = fakePeople.filter(fp => 
        !selectedPeople.some(sp => sp.FirstName === fp.FirstName)
    );

    if (availablePeople.length === 0) {
        console.log("No more people to add.");
        return;
    }

    // Select a random person from the availablePeople list
    const randomIndex = Math.floor(Math.random() * availablePeople.length);
    const randomPerson = { 
        ...availablePeople[randomIndex], 
        Identifier: (selectedPeople.length + 1).toString() 
    };

    await addPersonAndUpdate(randomPerson);
};


const addTransylvaniaRecords = async () => {
    if (selectedPeople.length === 0) {
        console.log("No people in the list to clone.");
        return;
    }

    // Directly work with the data you have without waiting for selectedPeople to update
    const lastPerson = selectedPeople[selectedPeople.length - 1];
    const transylvaniaClone = {
        ...lastPerson,
        LastName: 'Transylvania',
        Ssn: 'N/A', // Remove the SSN
        Identifier: (selectedPeople.length + 1).toString(),
    };

    // First update
    const updatedPeopleAfterFirstAddition = [...selectedPeople, transylvaniaClone];
    await addPersonAndUpdate(transylvaniaClone, updatedPeopleAfterFirstAddition);

    // Pause for a second (1000 milliseconds)
    await new Promise(resolve => setTimeout(resolve, 1500));

    const thirdRecord = {
        ...lastPerson,
        LastName: 'Transylvania',
        Identifier: (updatedPeopleAfterFirstAddition.length + 1).toString(),
    };

    // Since addPersonAndUpdate is async, we don't need the result here, just calling it is enough
    await addPersonAndUpdate(thirdRecord, [...updatedPeopleAfterFirstAddition, thirdRecord]);
};

const transposeFirstTwoLetters = async () => {
    const originalPerson = selectRandomPersonFromSmallestGroup();
    if (!originalPerson) {
        console.log("No person was selected.");
        return;
    }
    // Transpose the first two letters of the first name and adjust their casing
    const firstLetter = originalPerson.FirstName.charAt(1).toUpperCase(); // Now the second letter, but uppercase
    const secondLetter = originalPerson.FirstName.charAt(0).toLowerCase(); // Now the first letter, but lowercase
    const restOfName = originalPerson.FirstName.slice(2); // The rest of the name remains unchanged

    const transposedFirstName = firstLetter + secondLetter + restOfName;
    const newPerson = { 
        ...originalPerson, 
        FirstName: transposedFirstName, 
        Identifier: (selectedPeople.length + 1).toString() 
    };

    await addPersonAndUpdate(newPerson);
};


const createRandomTwin = async () => {
    if (selectedPeople.length === 0) {
        return;
    }

    const originalPerson = selectedPeople[selectedPeople.length - 1];
    const twinFirstName = originalPerson.FirstName + "-Twin";
    const twinSsn = "489-44-" + Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    const twin = { ...originalPerson, FirstName: twinFirstName, Ssn: twinSsn, Identifier: (selectedPeople.length + 1).toString() };

    await addPersonAndUpdate(twin);
};

const createFlippedName = async () => {
    const originalPerson = selectRandomPersonFromSmallestGroup();
    if (!originalPerson) {
        console.log("No person was selected.");
        return;
    }
    const twin = { ...originalPerson, FirstName: originalPerson.LastName, LastName: originalPerson.FirstName, Identifier: (selectedPeople.length + 1).toString() };

    await addPersonAndUpdate(twin);
};

const addPersonWithNewLastName = async () => {
    const originalPerson = selectRandomPersonFromSmallestGroup();
    if (!originalPerson) {
        console.log("No person was selected.");
        return;
    }
    const twin = { ...originalPerson, LastName: originalPerson.LastName + '-Hyphen', Identifier: (selectedPeople.length + 1).toString() };

    await addPersonAndUpdate(twin);
};

const addPersonWithSuffix = async () => {

    const originalPerson = selectRandomPersonFromSmallestGroup();
    if (!originalPerson) {
        console.log("No person was selected.");
        return;
    }
    const twin = { ...originalPerson, LastName: originalPerson.LastName + ' MD' , Identifier: (selectedPeople.length + 1).toString() };

    await addPersonAndUpdate(twin);
};

const addPersonWithNickname = async () => {
    const originalPerson = selectRandomPersonFromSmallestGroup();
    if (!originalPerson) {
        console.log("No person was selected.");
        return;
    }

    // Generate a nickname. This example simply appends "The Great" to the person's first name.
    // You can adjust the logic to generate a nickname as needed.
    const nickname = nicknameLookup[originalPerson.FirstName] || originalPerson.FirstName;

    // Create a new person object based on the original person, but with the nickname
    // and a new identifier. Optionally, you can modify other attributes as needed.
    const newPersonWithNickname = {
        ...originalPerson,
        FirstName: nickname, // Use the generated nickname
        Identifier: (selectedPeople.length + 1).toString(), // Ensure a unique identifier
        // Reset or modify any other fields as necessary
        Ssn: "N/A", // Example of resetting the SSN
    };

    // Add the new person with the nickname to the list
    await addPersonAndUpdate(newPersonWithNickname);
};


// Refactored addPersonAndUpdate to immediately update state
const addPersonAndUpdate = async (person, updatedList) => {
    const updatedPeople = updatedList || [...selectedPeople, person];
    setSelectedPeople(updatedPeople); // Update state immediately
    const newIdentifier = parseInt(person.Identifier, 10);
    if (newIdentifier > highestIdentifier) {
        setHighestIdentifier(newIdentifier);
    }

    try {
        const apiResponse = await submitCSV(updatedPeople);
        handleUpdateToGroupedPeople(apiResponse, updatedPeople);
    } catch (error) {
        console.error("Error submitting CSV:", error);
    }
};

    const generateCSVContent = (updatedPeople) => {
        // Adjust headers to include 'Source' and 'Identifier'
        const headers = "Identifier,FirstName,LastName,Gender,CellPhoneNumber,Dob,Street,State,City,ZipCode,Ssn,Source\n";
        // Map over selectedPeople to generate each row, adding 'Source' as 'source' and 'Identifier' as the index + 1
        const rows = updatedPeople.map((person, index) => 
            `${person.Identifier},${person.FirstName},${person.LastName},${person.Gender},${person.CellPhoneNumber},${person.Dob},${person.Street},${person.State},${person.City},${person.ZipCode},${person.Ssn},source`
        ).join('\n');
        return headers + rows;
    };
    

    const submitCSV = async (updatedPeople) => {
        const csvContent = generateCSVContent(updatedPeople);
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const formData = new FormData();
        formData.append('csvFile', blob, 'filename.csv');

        try {
            const response = await axios.post('https://api.careevolutionapi.com/identity/v1/csv', formData, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            console.log('Server response:', response.data);
            const responseData = JSON.parse(response.data);
            return responseData;
            
        } catch (error) {
            console.error('Error submitting CSV:', error);
        }
    };

    function demographicsToBase64(dataStr) {
        //const dataStr = `${demographics.FirstName}${demographics.LastName}${demographics.Gender}${demographics.CellPhoneNumber}${demographics.Dob}${demographics.Street}${demographics.City}${demographics.State}${demographics.ZipCode}${demographics.Ssn}`;
        
        // Convert the string to a Base64-encoded string
        const base64Str = btoa(encodeURIComponent(dataStr).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)));
        
        // Truncate the Base64 string at 15 characters and add an ellipsis if it's longer
        const truncatedStr = base64Str.length > 15 ? base64Str.substring(0, 18) + '...' : base64Str;
      
        return truncatedStr;
      }
      
      
      
      const selectRandomPersonFromSmallestGroup = () => {
        if (groupedPeople.length === 0) {
          console.log("No groups available.");
          return;
        }
      
        // Determine the sizes of all groups
        const groupSizes = groupedPeople.map(group => group.Records.length);
      
        // Find the size of the smallest group
        const smallestGroupSize = Math.min(...groupSizes);
      
        // Filter to get only the smallest groups
        const smallestGroups = groupedPeople.filter(group => group.Records.length === smallestGroupSize);
      
        // Randomly select one of the smallest groups
        const randomSmallestGroup = smallestGroups[Math.floor(Math.random() * smallestGroups.length)];
      
        // Randomly select a person from the chosen smallest group
        if (randomSmallestGroup && randomSmallestGroup.Records.length > 0) {
          const randomPerson = randomSmallestGroup.Records[Math.floor(Math.random() * randomSmallestGroup.Records.length)];
          console.log("Selected person:", randomPerson);
          return randomPerson;
        } else {
          console.log("No people found in the smallest group.");
          return;
        }
      };
      

    

    const handleUpdateToGroupedPeople = (apiResponse, updatedPeople) => {
        // apiResponse should contain the new structure of grouped people,
        // including any new additions with identifiers that match those in selectedPeople
        const updatedGroups = apiResponse.map(group => ({
          ...group,
          Records: group.Records.map(record => {
            // Find the matching person in selectedPeople by identifier
            // This assumes an identifier is present in the record that matches one in selectedPeople
            const person = updatedPeople.find(p => p.Identifier === record.Identifier);
            // If a matching person is found, spread their details into the record
            // Otherwise, just return the record as is
            return person ? { ...record, ...person } : record;
          })
        }));
    
        setGroupedPeople(updatedGroups);
    };

    const toggleEdit = (personIndex) => {
        setGroupedPeople(currentGroups => currentGroups.map((group, index) => ({
          ...group,
          Records: group.Records.map((record, idx) => idx === personIndex ? {...record, isEditable: !record.isEditable} : record)
        })));
      };

      const handleReload = () => {
        window.location.reload(); // Reload the page
      };

      const handleEdit = async (e, identifier, field) => {
        const newValue = e.target.innerText;

        // Update the state with the new value
        const updatedPeople = selectedPeople.map(person => {
            if (person.Identifier === identifier) {
            return { ...person, [field]: newValue };
            }
            return person;
        });

        setSelectedPeople(updatedPeople);
        
          // Optionally debounce this call to prevent excessive requests
          const apiResponse = await submitCSV(updatedPeople);
        handleUpdateToGroupedPeople(apiResponse, updatedPeople);
        
      };

    return (

        
        
<div className="flex flex-col items-center mt-8 space-y-4">


  <div className="button-and-checkbox-container flex items-center justify-start space-x-4">
    <button className="btn btn-neutral" onClick={addRandomPerson} disabled={groupedPeople.length > 5}>Add Person</button>
    <button className="btn btn-accent" onClick={addTransylvaniaRecords} disabled={groupedPeople.length > 5}>Transitive</button>
    <button className="btn btn-neutral" onClick={createRandomTwin} disabled={groupedPeople.length > 5}>Twin</button>
    
    <button className="btn btn-accent" onClick={addPersonWithNickname}>Nickname</button>
    <button className="btn btn-neutral" onClick={createFlippedName}>Transpose Names</button>
    <button className="btn btn-accent" onClick={addPersonWithNewLastName}>New Last Name</button>
    <button className="btn btn-neutral" onClick={addPersonWithSuffix}>Suffix</button>
    <button className="btn btn-accent" onClick={transposeFirstTwoLetters}>Transpose Letters</button>
    <button className="btn bg-red-500" onClick={handleReload}>Start over</button>
    
    <div className="show-hash-checkbox ml-auto">
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={showHash}
          onChange={() => setShowHash(!showHash)}
        />
        <span>Show Hash</span>
      </label>
    </div>
  </div>
  
<div className="group-container flex justify-start flex-wrap mt-4">
    {groupedPeople
      .sort((a, b) => {
        const minIdA = Math.min(...a.Records.map(record => parseInt(record.Identifier, 10)));
        const minIdB = Math.min(...b.Records.map(record => parseInt(record.Identifier, 10)));
        return minIdA - minIdB;
      })
      .map((group, index) => (
        <div key={index} className="group flex flex-col items-center mr-8">
          {group.Records.sort((a, b) => parseInt(a.Identifier, 10) - parseInt(b.Identifier, 10)).map((person, personIndex) => (
            <div key={personIndex} className={`demographicsBox ${parseInt(person.Identifier, 10) === highestIdentifier ? 'fadeIn' : ''}`}>
              <div contentEditable={person.isEditable}>
                {showHash ? (
                  <>
                    <p className="demographicDetail">{demographicsToBase64(person.FirstName + person.LastName)}</p>
                    <p className="demographicDetail">{demographicsToBase64(person.CellPhoneNumber)}</p>
                    <p className="demographicDetail">{demographicsToBase64(person.Dob)}</p>
                    <p className="demographicDetail">{demographicsToBase64(person.Street)}</p>
                    <p className="demographicDetail">{demographicsToBase64(person.City + person.State)}</p>
                    <p className="demographicDetail">{demographicsToBase64(person.Ssn)}</p>
                  </>
                ) : (
                  <>
                    <p className="demographicDetail">
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'FirstName')} suppressContentEditableWarning={true}>
      {person.FirstName}
    </span>{" "}
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'LastName')} suppressContentEditableWarning={true}>
      {person.LastName}
    </span>
  </p>
  <p className="demographicDetail">
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'Dob')} suppressContentEditableWarning={true}>
      {person.Dob}
    </span>
  </p>
  <p className="demographicDetail">
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'CellPhoneNumber')} suppressContentEditableWarning={true}>
      {person.CellPhoneNumber}
    </span>
  </p>
  <p className="demographicDetail">
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'Street')} suppressContentEditableWarning={true}>
      {person.Street}
    </span>
  </p>
  <p className="demographicDetail">
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'City')} suppressContentEditableWarning={true}>
      {person.City}
    </span>,{" "}
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'State')} suppressContentEditableWarning={true}>
      {person.State}
    </span>{" "}
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'ZipCode')} suppressContentEditableWarning={true}>
      {person.ZipCode}
    </span>
  </p>
  <p className="demographicDetail">
    <span contentEditable onBlur={(e) => handleEdit(e, person.Identifier, 'Ssn')} suppressContentEditableWarning={true}>
      {person.Ssn}
    </span>
  </p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
    ))}
  </div>


</div>

    );
}

export default CSVCreator;